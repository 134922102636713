// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-villager-details-page-js": () => import("./../../../src/components/VillagerDetailsPage.js" /* webpackChunkName: "component---src-components-villager-details-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-data-campsite-tracked-js": () => import("./../../../src/pages/data/campsite/tracked.js" /* webpackChunkName: "component---src-pages-data-campsite-tracked-js" */),
  "component---src-pages-data-index-js": () => import("./../../../src/pages/data/index.js" /* webpackChunkName: "component---src-pages-data-index-js" */),
  "component---src-pages-data-mystery-islands-by-personality-js": () => import("./../../../src/pages/data/mystery-islands/by-personality.js" /* webpackChunkName: "component---src-pages-data-mystery-islands-by-personality-js" */),
  "component---src-pages-data-mystery-islands-by-species-js": () => import("./../../../src/pages/data/mystery-islands/by-species.js" /* webpackChunkName: "component---src-pages-data-mystery-islands-by-species-js" */),
  "component---src-pages-data-mystery-islands-by-villager-js": () => import("./../../../src/pages/data/mystery-islands/by-villager.js" /* webpackChunkName: "component---src-pages-data-mystery-islands-by-villager-js" */),
  "component---src-pages-data-mystery-islands-tracked-js": () => import("./../../../src/pages/data/mystery-islands/tracked.js" /* webpackChunkName: "component---src-pages-data-mystery-islands-tracked-js" */),
  "component---src-pages-data-settings-js": () => import("./../../../src/pages/data/settings.js" /* webpackChunkName: "component---src-pages-data-settings-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-residents-js": () => import("./../../../src/pages/residents.js" /* webpackChunkName: "component---src-pages-residents-js" */),
  "component---src-pages-villagers-js": () => import("./../../../src/pages/villagers.js" /* webpackChunkName: "component---src-pages-villagers-js" */)
}

